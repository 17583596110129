import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import { artDesign } from '../../data/data';
import loadable from '@loadable/component';

const SubMenu = loadable(() => import('../../components/subMenu'));
const Grid = loadable(() => import('../../components/grid'));
const CardWithoutImage = loadable(() => import('../../components/card-without-image'));

const Cards = ({ data, location }) => {
  const cards = data.allMarkdownRemark.edges;

  const metaData = {
    title: 'Картички'
  };

  return (
    <Layout metaData={metaData}>
      <SubMenu location={location.pathname} sectionItems={artDesign} />
      <Grid mode="dark">
        {cards.map((item, i) => {
          const card = item.node.frontmatter;
          return (
            <CardWithoutImage
              key={`key${i}`}
              title={card.title}
              bg={card.coverImage ? card.coverImage.childImageSharp.fluid.src : null}
              url={item.node.fields.slug}
            />
          );
        })}
      </Grid>
    </Layout>
  );
};

export default Cards;

export const query = graphql`
  query cardsArtDesign {
    allMarkdownRemark(filter: { fields: { slug: { regex: "/арт-дизайн/картички/" } } }) {
      edges {
        node {
          frontmatter {
            title
            coverImage {
              ...largeImage
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
